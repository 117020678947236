body {
  margin: 0;

  font-family: -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai",sans-serif;

  --green: #4BB34B;
  --opacity_green: #EBF7EB;
  --gold: #FFA000;
  --silver: #99A2AD;
  --bronze: #F08244;
}

body[scheme="space_gray"],
body[scheme="client_dark"] {
  --green: #E2E3E7;
  --opacity_green: #363739;

  .Button--lvl-commerce {
    background-color: var(--green);
    color: #000000;
  }
}

* {
  box-sizing: border-box;
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.hide {
  display: none;
}

.root__icon {
  width: 28px;
  height: 28px;
  background-color: var(--content_placeholder_icon);
}

.TabbarItem--selected {
  .root__icon {
    background-color: var(--green);
  }
}

.root__icon--races {
  mask-image: url('../assets/images/races_icon.svg');
}

.root__icon--stats {
  mask-image: url('../assets/images/stats_icon.svg');
}

.root__icon--profile {
  mask-image: url('../assets/images/profile_icon.svg');
}

.PanelHeader {
  .PanelHeaderButton {
    .Icon {
      color: var(--green);
    }
  }
}

.PanelHeaderButton {
  .Icon {
    width: 100% !important;
    height: 100% !important;
  }
}


.PanelHeader--ios {
  .PanelHeader {
    &__in {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: var(--safe-area-inset-top);
    }

    &__left {
      position: absolute;
      z-index: 10;
      left: 0;

      display: flex;
      padding-left: 12px !important;
      align-items: center;
      height: 100%;
    }

    &__content {
      position: absolute;

      width: 100%;

      background-color: var(--background_content);
    }
  }
}


.PanelHeader__content {
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: var(--text_primary);
}

.Checkbox__input:checked~.Checkbox__container .Checkbox__icon {
  color: var(--background_content);

  background-color: var(--green);
  border-color: var(--green);
}

.Radio__input:checked~.Radio__container .Radio__icon {
  border-color: var(--green) !important;
}

.Radio--ios .Radio__input:checked~.Radio__container .Radio__icon::after {
  background-color: var(--green);
}

.Radio__description {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--text_primary);
}


.FixedLayout--bottom {
  padding-bottom: calc(var(--safe-area-inset-bottom) + 12px) !important;
  .Div {
    padding: 0 12px;
  }
}

.CardScroll__in::before,
.CardScroll__in::after {
  width: 12px !important;
}

.Link {
  color: var(--green);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
